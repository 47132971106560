import React from "react";

const Shead = ({ title, color, bgColor, width, Radius }) => {
  return (
    <th
      style={{
        border: "2px solid #0040ff",
        textAlign: "center",
        color: color,
        boxSizing: "border-box",
        fontWeight: 600,
        fontSize: 17,
        backgroundColor: bgColor,
        width: width,
        borderRadius: `${Radius}px 0px 0px 0px`,
      }}
    >
      <span>{title}</span>
    </th>
  );
};

export default Shead;
