import React from "react";

const SubjectR = ({ text, rowSpan }) => {
  return (
    <td
      style={{
        border: "2px solid #1a8cff",
        fontWeight: "bold",
        fontSize: 14,
        textAlign: "center",
        color: "#ffaa00",
        boxSizing: "border-box",
        padding: 30,
      }}
      rowSpan={rowSpan}
    >
      {text}
    </td>
  );
};

export default SubjectR;
