import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import HomeworkForm from "../../components/Homeworks/HomeworkForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
import { useParams } from "react-router-dom";

const EditHomework = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm();
  const [homework, sethomework] = useState(null);
  const [teacher, setTeacher] = useState({});
  const teacherID = JSON.parse(localStorage.getItem("user"))._id;
  useEffect(() => {
    fetch(API.me, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        setTeacher(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
    
    fetch(API.homeworks.info(teacherID, id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        const date = new Date(response.deadline);
        response.deadline =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          "-" +
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
        sethomework(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    data.teacherID = teacher._id;
    fetch(API.homeworks.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good", "updated successfully", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Ajouter un Homework"
      back={true}
      backRoute="/homeworks"
      submit={true}
    >
      <HomeworkForm
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        data={homework}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        teacherID={teacherID}
      />
    </Container>
  );
};

export default EditHomework;
