import React from "react";
const TableElement = ({ text, textalign, Ar }) => {
  return (
    <td
      style={{
        fontWeight: "800",
        fontSize: 12,
        textAlign: textalign,
        padding: "5px 2px",
        borderBottom: "black .5px solid",
      }}
    >
      {Ar ? <p> {text} - </p> : <p> - {text} </p>}
    </td>
  );
};

export default TableElement;
