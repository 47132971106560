import React from "react";
import Shead from "./Shead";
import Snote from "./Snote";
import Ssubject from "./Ssubject";
import Smoyenne from "./Smoyenne";
const CarnetSTable = ({
  unit,
  changeModuleRemarque,
  changeNote,
  unitInedx,
  changeNoteEdge,
}) => {
  return (
    <div className="card ">
      <div className="card-body" style={{ padding: 10 }}>
        <header
          style={{
            background: "#0040ff",
            fontSize: 25,
            fontWeight: "bold",
            textAlign: "center",
            borderRadius: 5,
            color: "white",
            height: 40,
            marginBottom: 2,
          }}
        >
          {unit.unitName}
        </header>

        <table className="table" style={{ backgroundColor: "#0040ff" }}>
          <thead>
            <tr>
              <Shead
                title="Matiéres"
                color="#ffffff"
                bgColor="#0040ff"
                width="20%"
              />
              <Shead
                title="Notes"
                color="#002db3"
                bgColor="#1a8cff"
                Radius="40"
                width="30%"
              />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  backgroundColor: "#ffffff",
                  borderTop: "2px solid #ffffff",
                }}
                colSpan="3"
              />
            </tr>

            {unit.subjects.map((subject, index) => (
              <tr key={index}>
                <Ssubject text={subject.name} />
                <Snote
                  note={subject.note}
                  changeNote={changeNote}
                  subjectIndex={index}
                  unitInedx={unitInedx}
                  min={subject.min ? subject.min : 0}
                  max={subject.max ? subject.max : 0}
                  changeNoteEdge={changeNoteEdge}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Smoyenne
        unitInedx={unitInedx}
        changeModuleRemarque={changeModuleRemarque}
        color="#ffaa00"
        moyenne={unit.unitMoyenne}
        fontSizeTitle="20px"
        title="Moyenne du Module"
        fontSizeMoyenne="25px"
        remarque={unit.unitRemarque}
      />
    </div>
  );
};

export default CarnetSTable;
