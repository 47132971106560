import React, { useState, useEffect } from "react";
import Container from "../../../components/Container/Container";
import Table from "../../../components/Table/Table";
import { API, ROUTER } from "../../../constants/env";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import TableAd from "../../../components/Table/TableAd";
import { get } from "react-hook-form";

const ListCarnet = () => {
  const fields = [
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Classe", field: "classe" },
    { title: "Triméstre", field: "trimestre" },
  ];
  const [data, setData] = useState([]);
  const [teacherId, setTeacherId] = useState("");

  useEffect(() => {
    const getUserId = async () => {
      try {
        const user = await localStorage.getItem("user");
        const parsed = JSON.parse(user);
        // console.log(parsed._id);
        setTeacherId(parsed._id);
      } catch {
        console.log("errrrr");
      }
    };
    getUserId();
  }, []);
  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse
  ) => {
    fetch(
      API.carnetStudent.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&filterByClasse=${idClasse}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        console.log({ response });
        const result = response.data.map((elem) => ({
          _id: elem._id,
          firstName: elem.student.profil.firstName,
          lastName: elem.student.profil.lastName,
          classe: elem?.student?.classe?.name,
          trimestre: (
            <div>
              {" "}
              {elem.reportCards.map((card) => (
                <Link
                  as="button"
                  className={`btn  ${
                    card?.status === "cancled"
                      ? "btn-danger"
                      : card?.status === "pending"
                      ? "btn-warning"
                      : card?.status === "approved"
                      ? "btn-success"
                      : "btn-primary"
                  } btn-sm mx-1`}
                  to={`${ROUTER.CARNETS.EDIT(card._id)}/${
                    elem.student.profil.firstName +
                    " " +
                    elem.student.profil.lastName
                  }`}
                  onClick={() => localStorage.setItem("tris", card.semestre)}
                >
                  {/* work here */}
                  {`trimestre ${
                    card.semestre === "premier"
                      ? 1
                      : card.semestre === "deuxieme"
                      ? 2
                      : 3
                  }`}
                </Link>
              ))}
            </div>
          ),
        }));

        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        console.log({ e });

        swal("Error", e.message, "error");
      });
  };
  return (
    <Container name="Carnets de Notes">
      <TableAd
        name="Carnets"
        fields={fields}
        data={data}
        hasInfo
        classeApi={API.carnetStudent.listStudents}
        pageName="carnets"
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
};

export default ListCarnet;
