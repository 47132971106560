import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../../constants/env";

const HomeworkForm = (props) => {
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    //getClasses
    fetch(API.classes.all, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        setClasses(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);
  useEffect(() => {
    if (props.teacherID) {
      fetch(API.subjects.getMySubjects(props.teacherID), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          console.log(response);
          setSubjects(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });

      console.log("DATA OBJ => ", props.data);
    }
  }, [props.teacherID]);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              {" "}
              <div className="form-group">
                <label>
                  Matiere <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="subjectID"
                  placeholder="Selectionnez la matiere"
                >
                  {subjects.map(({ name, level, _id }, index) => {
                    if (props.data?.subjectID === _id)
                      return (
                        <option selected value={_id} key={index}>
                          {`${name}${level}`}
                        </option>
                      );
                    return (
                      <option value={_id} key={index}>
                        {`${name}${level}`}
                      </option>
                    );
                  })}
                </select>
                {props.errors?.subject && (
                  <span className="text-danger">La matiere est requise</span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Classe <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="classID"
                  placeholder="Selectionnez la matiere"
                >
                  {classes.map(({ name, _id }, index) => {
                    if (props.data?.classID === _id)
                      return (
                        <option selected value={_id} key={index}>
                          {name}
                        </option>
                      );
                    return (
                      <option value={_id} key={index}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {props.errors.class && (
                  <span className="text-danger">name required</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  Titre <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="title"
                  type="text"
                  className="form-control"
                  placeholder="Entrer un Titre ..."
                  defaultValue={props.data?.title}
                />
                {props.errors?.title && (
                  <span className="text-danger">Description est requis</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  ref={props.registerRequired}
                  name="description"
                  className="form-control"
                  placeholder="Entrer un Description ..."
                  defaultValue={props.data?.description}
                />
                {props.errors?.description && (
                  <span className="text-danger">Description est requis</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>
              DeadLine <span className="text-danger">*</span>
            </label>
            <input
              ref={props.registerRequired}
              name="deadline"
              type="date"
              className="form-control"
              placeholder="Entrer un date"
              defaultValue={props.data?.deadline}
            />
            {props.errors?.deadline && (
              <span className="text-danger">DeadLine est requis</span>
            )}
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Submit
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default HomeworkForm;
