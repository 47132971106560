import React from "react";
import Sremarque from "./Sremarque";

const roundN = (num1) => {
  const num = num1?.toString(); //If it's not already a String
  const res = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
  return res;
};

const Smoyenne = ({
  moyenne,
  fontSizeTitle,
  title,
  fontSizeMoyenne,
  color,
  remarque,
  confirm,
  changeCarnetRemarque,
  changeModuleRemarque,
  general,
  unitInedx,
  saveCarnet,
}) => {
  return (
    <div className="card ">
      <div className="card-body">
        <header
          style={{
            color: color,
            fontWeight: "bolder",
            fontSize: fontSizeTitle,
            marginBottom: 8,
          }}
        >
          {title}
        </header>
        <div
          style={{
            display: "inline-block",
            width: "35%",
            position: "relative",
          }}
        >
          <span
            style={{
              textAlign: "center",
              margin: "0 auto",
              fontSize: fontSizeMoyenne,
              color: "#474444",
              fontWeight: 800,
            }}
          >
            {moyenne ? roundN(moyenne) : 0}
          </span>
        </div>
        <div style={{ display: "inline-block", width: "65%" }}>
          <Sremarque
            general={general}
            unitInedx={unitInedx}
            color={color}
            remarque={remarque}
            changeCarnetRemarque={changeCarnetRemarque}
            changeModuleRemarque={changeModuleRemarque}
          />
        </div>

        {confirm && (
          <div>
            <button
              className="btn btn-success btn-lg btn-block mt-3"
              onClick={() => saveCarnet()}
            >
              Confirm Carnet
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Smoyenne;
