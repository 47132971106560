import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";
import moment from "moment";

const ListHomework = () => {
  //const fields = [

  const fields = [
    { title: "Titre", field: "title" },
    { title: "Contenu", field: "content" },
    { title: "Type", field: "type" },
    { title: "Matiere", field: "subject" },
    { title: "Receveur", field: "receveur" },
    { title: "Date creation", field: "created_date" },

    //  { title: "Classe", field: "classes" },
  ];
  const [data, setData] = useState([]);
  function createMarkup(text) {
    return { __html: text };
  }
  const fetchData = async (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal
  ) => {
    // const idTeacher = localStorage.getItem("user")._id;
    // console.log("idTeacher =>",idTeacher)
    try {
      fetch(
        API.elearning.list +
          `?page=${currentPage}&count=${count}&searchQuery=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            console.log(response);
            return swal("Error", response.message, "error");
          }
          console.log("this is reponse data", response.data);
          const result = response.data.map((elem) => ({
            _id: elem._id,
            title: elem.title,
            content: (
              <div dangerouslySetInnerHTML={createMarkup(elem.content)} />
            ),
            type: elem.type,
            subject: elem.subject ? elem.subject.name : "",
            classes: elem.classes.map((elem) => `${elem.name} ,`),
            receveur: getReceiver(elem),
            created_date: moment(elem.created_at).format("DD/MM/YYYY"),
          }));
          setData(result);
          setTotalPage(response.totalPage);
          setTotal(response.total);
        })
        .catch((e) => {
          swal("Error", e.message, "error");
        });
    } catch (e) {
      swal("Error", e.message, "error");
    }
  };

  const getReceiver = ({ sender, classes, students }) => {
    // console.log(type);
    switch (sender) {
      case "éléves":
        return students.reduce((str, student) => {
          return `${str} ${student.profil.firstName} ${student.profil.lastName},`;
        }, "éléves :");
      case "classes":
        return classes.reduce((str, classe) => {
          return `${str} ${classe.name},`;
        }, "Classes :");
      default:
        return "Tout l'école";
    }
  };
  console.log(data);
  return (
    <Container
      name="Elearning"
      actionRoute={ROUTER.ELEARNING.CREATE}
      actionName="Ajouter"
    >
      <Table
        name="Elearning"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.ELEARNING.EDIT(id)}
        deleteApiRoute={API.elearning.remove}
        deleteMultiple={API.elearning.deleteMultiple}
        fetchData={fetchData}
        showEdit={false}
      />
    </Container>
  );
};

export default ListHomework;
