import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";
import moment from "moment";

const ListHomework = () => {
  //const fields = [

  const fields = [
    { title: "Matiere", field: "subject" },
    { title: "Classe", field: "class" },
    { title: "Title", field: "title" },
    { title: "Description", field: "description" },
    { title: "Deadline", field: "deadline" },
  ];
  const [data, setData] = useState([]);

  const fetchData = async (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal
  ) => {
    // const idTeacher = localStorage.getItem("user")._id;
    // console.log("idTeacher =>",idTeacher)
    try {
      const teacherID = JSON.parse(localStorage.getItem("user"))._id;
      fetch(
        API.homeworks.list(teacherID) +
          `?page=${currentPage}&count=${count}&searchQuery=${search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            console.log(response);
            return swal("Error", response.message, "error");
          }

          const result = response.data.map((elem) => ({
            _id: elem._id,
            subject: elem.subject.name,
            class: elem.class?.name,
            title: elem.title,
            description: elem.description,

            deadline: moment(elem.deadline).format("L"),
          }));
          setData(result);
          setTotalPage(response.totalPage);
          setTotal(response.total);
        })
        .catch((e) => {
          swal("Error", e.message, "error");
        });
    } catch (e) {
      swal("Error", e.message, "error");
    }
  };
  return (
    <Container
      name="HomeWorks"
      actionRoute={ROUTER.HOMEWORKS.CREATE}
      actionName="Ajouter un homework"
    >
      <Table
        name="HomeWorks"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.HOMEWORKS.EDIT(id)}
        deleteApiRoute={API.homeworks.remove}
        deleteMultiple={API.homeworks.deleteMultiple}
        fetchData={fetchData}
        showEdit={true}
      />
    </Container>
  );
};

export default ListHomework;
