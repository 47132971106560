import Student from "../pages/Emploi/Student";
import Teacher from "../pages/Emploi/Teacher";
import ListUsers from "../pages/Users/ListUsers";
import AddUsers from "../pages/Users/AddUser";
import EditUsers from "../pages/Users/EditUser";
import Logout from "../components/Logout/Logout";
import ListClasses from "../pages/Classes/ListClasses";
import AddClasses from "../pages/Classes/AddClasse";
import EditClasses from "../pages/Classes/EditClasse";

import ListHomework from "../pages/Homeworks/ListHomework";
import AddHomework from "../pages/Homeworks/AddHomework";
import EditHomework from "../pages/Homeworks/EditHomework";

import ListElearning from "../pages/Elearning/ListElearning";
import AddElearning from "../pages/Elearning/AddElearning";
import EditElearning from "../pages/Elearning/EditElearning";

import NotFound from "../components/NotFound/NotFound";

import withAuth from "../libs/withAuth";
import ListCarnetPrepa from "../pages/Carnet/Prepa/ListCarnetPrepa";
import CarnetPrepa from "../pages/Carnet/Prepa/CarnetPrepa";
import ListCarnet from "../pages/Carnet/Student/ListCarnet";
import CarnetStudent from "../pages/Carnet/Student/CarnetStudent";

export const routes = [
  // ==========> Acceuil
  {
    path: "/",
    exact: true,
    main: withAuth(ListHomework),
  },
  // {
  //   // ========> Emploi
  //   path: "/emploi/student",
  //   main: withAuth(Student),
  // },
  // {
  //   path: "/emploi/teacher",
  //   main: withAuth(Teacher),
  // },
  // ==========> HomeWork
  {
    path: "/homeworks",
    exact: true,
    main: withAuth(ListHomework),
  },
  {
    path: "/homeworks/new",
    main: AddHomework,
  },
  {
    path: "/homeworks/edit/:id",
    main: withAuth(EditHomework),
  },
  {
    path: "/Logout",
    main: withAuth(Logout),
  },
  {
    path: "/elearning",
    exact: true,
    main: withAuth(ListElearning),
  },
  {
    path: "/elearning/new",
    main: AddElearning,
  },
  {
    path: "/elearning/edit/:id",
    main: withAuth(EditElearning),
  },

  // ============> Carnet Prepa

  {
    path: "/carnetPrepa",
    main: withAuth(ListCarnetPrepa),
    exact: true,
  },

  {
    path: "/carnetPrepa/st/:id/:trimestre/:trimstereNumber",
    main: withAuth(CarnetPrepa),
  },

  // ============> Carnet de note
  {
    path: "/carnets",
    exact: true,
    main: withAuth(ListCarnet),
  },

  {
    path: "/carnets/st/:id/:trimestre",
    main: withAuth(CarnetStudent),
  },

  // ========> users
  // {
  //   path: "/users",
  //   exact: true,
  //   main: withAuth(ListUsers),
  // },
  // {
  //   path: "/user/new",
  //   main: withAuth(AddUsers),
  // },
  // {
  //   path: "/user/edit",
  //   main: withAuth(EditUsers),
  // },
  // ===========> Absences/P

  // ==========> Classes
  // {
  //   path: "/classes",
  //   exact: true,
  //   main: withAuth(ListClasses),
  // },
  // {
  //   path: "/classe/new",
  //   main: withAuth(AddClasses),
  // },
  // {
  //   path: "/classe/edit",
  //   main: withAuth(EditClasses),
  // },

  // ========> not Found
  {
    path: "/404",
    main: withAuth(NotFound),
  },
];
