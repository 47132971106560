import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CarnetP from "../../../components/CarnetPrepa/CarnetP";
import Container from "../../../components/Container/Container";

const CarnetPrepa = () => {
  const { trimestre, trimstereNumber } = useParams();
  console.log({ trimstereNumber });
  return (
    <Container
      name={`Carnet de Note : ${trimestre}`}
      back={true}
      backRoute="/carnetPrepa"
      submit={true}
      pdf
    >
      <CarnetP trimestre={trimstereNumber} />
    </Container>
  );
};

export default CarnetPrepa;
