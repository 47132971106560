import React from "react";

const Sremarque = ({
  remarque,
  color,
  changeCarnetRemarque,
  changeModuleRemarque,
  general,
  unitInedx,
}) => {
  return (
    <div
      style={{
        fontWeight: "bold",
        fontSize: 20,
        padding: 10,
      }}
    >
      <label style={{ borderBottom: `2px solid ${color}` }}>
        Recommandataion de l'institutrice :
      </label>

      <textarea
        value={remarque}
        className="form-control"
        onChange={(e) =>
          general
            ? changeCarnetRemarque(e.target.value)
            : changeModuleRemarque(unitInedx, e.target.value)
        }
        placeholder="................................."
        style={{ minWidth: "100%", maxHeight: "150px" }}
      />
    </div>
  );
};

export default Sremarque;
