import React, { useState } from "react";
import Container from "../../../components/Container/Container";
import Table from "../../../components/Table/Table";
import { API, ROUTER } from "../../../constants/env";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import TableAd from "../../../components/Table/TableAd";

const ListCarnetPrepa = () => {
  const fields = [
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Classe", field: "classe" },
    { title: "Triméstre", field: "trimestre" },
  ];
  const [data, setData] = useState([]);

  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse
  ) => {
    fetch(
      API.carnetPrepa.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&filterByClasse=${idClasse}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map((elem) => ({
          _id: elem._id,
          firstName: elem.student.profil.firstName,
          lastName: elem.student.profil.lastName,
          classe: elem.student.classe.name,
          trimestre: (
            <div>
              {" "}
              {elem.reportCards.map((card, index) => (
                <Link
                  key={index}
                  as="button"
                  className={`btn  ${
                    card?.status === "cancled"
                      ? "btn-danger"
                      : card?.status === "pending"
                      ? "btn-warning"
                      : card?.status === "approved"
                      ? "btn-success"
                      : "btn-primary"
                  } btn-sm mx-1`}
                  to={`${ROUTER.CARNETPREPA.EDIT(card._id)}/${
                    elem.student.profil.firstName +
                    " " +
                    elem.student.profil.lastName
                  }/${
                    card.semestre === "premier"
                      ? "1er Trimestre"
                      : card.semestre === "deuxieme"
                      ? "2eme Trimestre"
                      : "3eme Trimestre"
                  }`}
                >
                  {/* work here */}
                  {`trimestre ${
                    card.semestre === "premier"
                      ? 1
                      : card.semestre === "deuxieme"
                      ? 2
                      : 3
                  }`}
                </Link>
              ))}
            </div>
          ),
        }));
        console.log({ result: result });
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      });
  };
  return (
    <Container name="Carnet Preparatoire">
      <TableAd
        pageName="CarnetPrepa"
        name="CarnetPrepa"
        hasInfo
        classeApi={API.carnetPrepa.listPrepas}
        fields={fields}
        data={data}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
};

export default ListCarnetPrepa;
