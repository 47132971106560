import React from "react";

const navigationConfig = [
  // {
  //   subMenu: false,
  //   name: "Classes",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       xmlnsXlink="http://www.w3.org/1999/xlink"
  //       width="24px"
  //       height="24px"
  //       viewBox="0 0 24 24"
  //       version="1.1"
  //     >
  //       <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  //         <polygon points="0 0 24 0 24 24 0 24" />
  //         <path
  //           d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
  //           fill="#000000"
  //           fillRule="nonzero"
  //           opacity="0.3"
  //         />
  //         <path
  //           d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
  //           fill="#000000"
  //           fillRule="nonzero"
  //         />
  //       </g>
  //     </svg>
  //   ),
  //   route: "/classes",
  // },

  {
    subMenu: false,
    name: "HomeWorks",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={24} />
          <path
            fill="#000000"
            d="M155.505,290.982l3.398,55.067c0.337,5.473,2.906,10.566,7.106,14.09c4.225,3.544,9.69,5.163,15.11,4.552l54.821-6.216
				c5.094-0.579,9.772-3.09,13.067-7.018l102.847-122.565c7.099-8.461,5.996-21.076-2.466-28.177l-58.219-48.852
				c-4.063-3.41-9.317-5.059-14.599-4.603c-5.284,0.462-10.168,3.005-13.578,7.068L160.146,276.895
				C156.851,280.822,155.189,285.865,155.505,290.982z M280.779,195.359l27.577,23.14l-84.857,101.13l-25.968,2.944l-1.61-26.085
				L280.779,195.359z"
          />
          <path
            fill="#000000"
            d="M426,0H181.772c-5.304,0-10.392,2.107-14.142,5.857l-95.772,95.771C68.107,105.379,66,110.466,66,115.771V492
				c0,11.046,8.954,20,20,20h340c11.046,0,20-8.954,20-20V20C446,8.954,437.046,0,426,0z M406,472H106V124.056L190.056,40H406V472z"
          />
        </g>
      </svg>
    ),
    route: "/homeworks",
  },
  {
    subMenu: true,
    name: "Carnet de note",
    route: "/carnets",
    icon: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 150 150"
        xmlSpace="preserve"
      >
        <g id="_14-trophy" data-name="14-trophy">
          <g
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
            id="glyph"
          >
            <rect x={0} y={0} width={24} height={24} />
            <path d="M51.977,63.768C60.283,80.763,79.42,94.745,88,100.4v15.147L53.914,136h84.432L104,115.392V100.4c8.58-5.657,27.717-19.639,36.023-36.634C168.343,60.788,176,26.518,176,8a8,8,0,0,0-8-8H24a8,8,0,0,0-8,8C16,26.518,23.657,60.788,51.977,63.768ZM144,46.192V16h15.382C158.169,25.581,154.58,40.969,144,46.192ZM48,16V46.192C37.419,40.969,33.83,25.577,32.618,16Z" />
            <path d="M188,136a12.013,12.013,0,0,1-12-12,4,4,0,0,0-8,0,12.013,12.013,0,0,1-12,12,4,4,0,0,0,0,8,12.013,12.013,0,0,1,12,12,4,4,0,0,0,8,0,12.013,12.013,0,0,1,12-12,4,4,0,0,0,0-8Z" />
            <path d="M172,63.087a4,4,0,0,0-4,4,12.013,12.013,0,0,1-12,12,4,4,0,0,0,0,8,12.013,12.013,0,0,1,12,12,4,4,0,0,0,8,0,12.013,12.013,0,0,1,12-12,4,4,0,0,0,0-8,12.013,12.013,0,0,1-12-12A4,4,0,0,0,172,63.087Z" />
            <path d="M56,108a4,4,0,0,0-4-4A12.013,12.013,0,0,1,40,92a4,4,0,0,0-8,0,12.013,12.013,0,0,1-12,12,4,4,0,0,0,0,8,12.013,12.013,0,0,1,12,12,4,4,0,0,0,8,0,12.013,12.013,0,0,1,12-12A4,4,0,0,0,56,108Z" />
            <path d="M48.13,184a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V144h-96Z" />
          </g>
        </g>
      </svg>
    ),
    subMenuList: [
      {
        name: "Carnet",
        route: "/carnets",
      },

      {
        name: "Carnet Prepa",
        route: "/carnetPrepa",
      },
    ],
  },
  {
    subMenu: false,
    name: "Elearning",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={24} />
          <path
            fill="#000000"
            d="M155.505,290.982l3.398,55.067c0.337,5.473,2.906,10.566,7.106,14.09c4.225,3.544,9.69,5.163,15.11,4.552l54.821-6.216
				c5.094-0.579,9.772-3.09,13.067-7.018l102.847-122.565c7.099-8.461,5.996-21.076-2.466-28.177l-58.219-48.852
				c-4.063-3.41-9.317-5.059-14.599-4.603c-5.284,0.462-10.168,3.005-13.578,7.068L160.146,276.895
				C156.851,280.822,155.189,285.865,155.505,290.982z M280.779,195.359l27.577,23.14l-84.857,101.13l-25.968,2.944l-1.61-26.085
				L280.779,195.359z"
          />
          <path
            fill="#000000"
            d="M426,0H181.772c-5.304,0-10.392,2.107-14.142,5.857l-95.772,95.771C68.107,105.379,66,110.466,66,115.771V492
				c0,11.046,8.954,20,20,20h340c11.046,0,20-8.954,20-20V20C446,8.954,437.046,0,426,0z M406,472H106V124.056L190.056,40H406V472z"
          />
        </g>
      </svg>
    ),
    route: "/elearning",
  },
  // {
  //   subMenu: false,
  //   name: "Absence/Punition",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       xmlnsXlink="http://www.w3.org/1999/xlink"
  //       width="24px"
  //       height="24px"
  //       viewBox="0 0 24 24"
  //       version="1.1"
  //     >
  //       <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  //         <polygon points="0 0 24 0 24 24 0 24" />
  //         <path
  //           d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
  //           fill="#000000"
  //           fillRule="nonzero"
  //           opacity="0.3"
  //         />
  //         <path
  //           d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
  //           fill="#000000"
  //           fillRule="nonzero"
  //         />
  //       </g>
  //     </svg>
  //   ),
  //   route: "/absences",
  // },

  // {
  //   subMenu: true,
  //   name: "Emploi",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       xmlnsXlink="http://www.w3.org/1999/xlink"
  //       width="24px"
  //       height="24px"
  //       viewBox="0 0 24 24"
  //       version="1.1"
  //     >
  //       <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  //         <rect x={0} y={0} width={24} height={24} />
  //         <path
  //           d="M10.9630156,7.5 L11.0475062,7.5 C11.3043819,7.5 11.5194647,7.69464724 11.5450248,7.95024814 L12,12.5 L15.2480695,14.3560397 C15.403857,14.4450611 15.5,14.6107328 15.5,14.7901613 L15.5,15 C15.5,15.2109164 15.3290185,15.3818979 15.1181021,15.3818979 C15.0841582,15.3818979 15.0503659,15.3773725 15.0176181,15.3684413 L10.3986612,14.1087258 C10.1672824,14.0456225 10.0132986,13.8271186 10.0316926,13.5879956 L10.4644883,7.96165175 C10.4845267,7.70115317 10.7017474,7.5 10.9630156,7.5 Z"
  //           fill="#000000"
  //         />
  //         <path
  //           d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z"
  //           fill="#000000"
  //           opacity="0.3"
  //         />
  //       </g>
  //     </svg>
  //   ),
  //   subMenuList: [
  //     {
  //       name: "Emploi Eleve",
  //       route: "/emploi/student",
  //     },
  //     {
  //       name: "Emploi Enseignant",
  //       route: "/emploi/teacher",
  //     },
  //   ],
  // },
  // {
  //   subMenu: false,
  //   name: "Parametres",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       xmlnsXlink="http://www.w3.org/1999/xlink"
  //       width="24px"
  //       height="24px"
  //       viewBox="0 0 24 24"
  //       version="1.1"
  //     >
  //       <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  //         <rect x={0} y={0} width={24} height={24} />
  //         <path
  //           d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
  //           fill="#000000"
  //         />
  //         <path
  //           d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
  //           fill="#000000"
  //           opacity="0.3"
  //         />
  //       </g>
  //     </svg>
  //   ),
  //   route: "/settings",
  // },
  {
    subMenu: false,
    name: "Logout",
    icon: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={300} />
          <path
            fill="#000000"
            d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15
			c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724
			c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262
			C276.412,478.129,266.908,468.625,255.15,468.625z"
          />
          <path
            fill="#000000"
            d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173
			H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173
			c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575
			c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"
          />
        </g>
      </svg>
    ),
    route: "/logout",
  },
];
export default navigationConfig;
