import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { API } from "../../constants/env";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ElearningForm = (props) => {
  //const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const NotifReceiver = [
    { option: "Toute l'école", value: "All" },
    { option: "Classes", value: "classes" },
    { option: "éléves", value: "éléves" },
  ];

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [receiver, setReceiver] = useState("All");

  const setDate = (api) => {
    setLoading(!loading);
    fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setLoading(!loading);
          return swal("Error", response.message, "error");
        }

        response = response.map(({ _id, name, profil }) => ({
          value: _id,
          label: `${profil ? profil.firstName + " " + profil.lastName : name}`,
        }));

        setList(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(!loading);
        return swal("Error", e.message, "error");
      });
  };
  useEffect(() => {
    switch (receiver) {
      case "classes":
        setDate(API.classes.all);
        if (props.data && props.data.sender === receiver) fillTable("classes");
        else {
          props.setValue("value", null);
        }
        break;
      case "éléves":
        setDate(API.students.all);
        if (props.data && props.data.sender === receiver) fillTable("students");
        else {
          props.setValue("value", null);
          //ae
        }
        break;

      default:
        break;
    }
  }, [receiver]);

  const fillTable = (dest) => {
    props.setValue(
      "value",
      props.data[dest].map((res) => ({
        value: res._id ? res._id : res,
        label: `${
          res.profil
            ? res.profil.firstName + " " + res.profil.lastName
            : res.name
        }`,
      }))
    );
  };

  useEffect(() => {
    if (props.data) {
      setReceiver(props.data.sender);
    }
  }, [props]);

  const getLists = () => {
    if (receiver !== "All")
      return (
        <div className="form-group">
          <label>
            Les {receiver} <span className="text-danger">*</span>
          </label>
          <Controller
            control={props.control}
            as={
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                isDisabled={loading}
                isLoading={loading}
              />
            }
            options={list}
            onChange={([selected]) => {
              return { value: selected };
            }}
            name="value"
            isClearable
            isMulti
            rules={{ required: true }}
            errors={props.errors.list}
          />
          {props.errors.value && (
            <span className="text-danger">list est requis</span>
          )}
        </div>
      );
  };

  useEffect(() => {
    if (props.teacherID) {
      fetch(API.subjects.getMySubjects(props.teacherID), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          console.log(response);
          setSubjects(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });

      console.log("DATA OBJ => ", props.data);
    }
  }, [props.teacherID]);
  /*
  useEffect(() => {
    if (props.data) {
      if (props.data.classes) {
        props.setValue(
          "classes",
          props.data?.classes?.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }))
        );
      }
    }
  }, [props]);
  */
  console.log("This is reponse data", props.data);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <label>
                Pour qui?
                <span className="text-danger"> *</span>
              </label>
              <select
                className="form-control"
                ref={props.register}
                name="sender"
                placeholder="Selectionnez..."
                onChange={(e) => {
                  setReceiver(e.target.value);
                }}
              >
                {NotifReceiver.map(({ option, value }, index) => {
                  if (props.data) {
                    if (value === props.data.sender) {
                      return (
                        <option selected value={value} key={index}>
                          {option}
                        </option>
                      );
                    } else {
                      return (
                        <option value={value} key={index}>
                          {option}
                        </option>
                      );
                    }
                  } else {
                    return (
                      <option value={value} key={index}>
                        {option}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-6">{getLists()}</div>

            <div className="col-12">
              {" "}
              <div className="form-group">
                <label>
                  Matiere <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="subjectID"
                  placeholder="Selectionnez la matiere"
                >
                  {subjects.map(({ name, level, _id }, index) => {
                    if (props.data?.subject._id === _id)
                      return (
                        <option selected value={_id} key={index}>
                          {`${name}${level}`}
                        </option>
                      );
                    return (
                      <option value={_id} key={index}>
                        {`${name}${level}`}
                      </option>
                    );
                  })}
                </select>
                {props.errors?.subject && (
                  <span className="text-danger">La matiere est requise</span>
                )}
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label>
                  Journée <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="day"
                  placeholder="Selectionnez le type"
                >
                  {[
                    {
                      id: "0",
                      value: "Lundi",
                    },
                    {
                      id: "1",
                      value: "Mardi",
                    },
                    {
                      id: "2",
                      value: "Mercredi",
                    },
                    {
                      id: "3",
                      value: "Jeudi",
                    },
                    {
                      id: "4",
                      value: "Vendredi",
                    },
                    {
                      id: "5",
                      value: "Samedi",
                    },
                  ].map((elem) => {
                    if (props.data) {
                      if (elem.id === props.data?.day) {
                        return (
                          <option selected value={elem.id}>
                            {elem.value}
                          </option>
                        );
                      } else {
                        return <option value={elem.id}>{elem.value}</option>;
                      }
                    } else {
                      return <option value={elem.id}>{elem.value}</option>;
                    }
                  })}
                </select>
                {props.errors.day && (
                  <span className="text-danger">Journée required</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>
                  Titre <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="title"
                  type="text"
                  className="form-control"
                  placeholder="Entrer un Titre ..."
                  defaultValue={props.data?.title}
                />
                {props.errors?.title && (
                  <span className="text-danger">Description est requis</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  Type <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="type"
                  placeholder="Selectionnez le type"
                >
                  {["cours", "exercices", "correction d'exercice"].map(
                    (elem) => {
                      if (props.data) {
                        if (elem === props.data?.type) {
                          return (
                            <option selected value={elem}>
                              {elem}
                            </option>
                          );
                        } else {
                          return <option value={elem}>{elem}</option>;
                        }
                      } else {
                        return <option value={elem}>{elem}</option>;
                      }
                    }
                  )}
                </select>
                {props.errors.type && (
                  <span className="text-danger">type required</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  Contenu <span className="text-danger">*</span>
                </label>
                <Editor
                  editorState={props.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={props.onEditorStateChange}
                />

                {props.errors?.title && (
                  <span className="text-danger">Description est requis</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  Fichiers <span className="text-danger">*</span>
                </label>
                <FilePond
                  files={props.files}
                  allowReorder={true}
                  allowMultiple={true}
                  onupdatefiles={props.setFiles}
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Submit
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default ElearningForm;
