import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { API } from "../../constants/env";
import CarnetTable from "./CarnetTable";

const CarnetP = ({ trimestre }) => {
  const { id } = useParams();
  const [carnet, setCarnet] = useState(null);

  useEffect(() => {
    fetch(API.carnetPrepa.info(id, trimestre), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setCarnet(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);

  const valider = () => {
    fetch(API.carnetPrepa.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(carnet),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Carnet modifie", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };

  const changeRemarque = (remarque, index) => {
    const units = carnet.units;
    units[index].unitRemarque = remarque;
    setCarnet({ ...carnet, units });
  };

  const changeNote = (note, unitIndex, subjectIndex, goalIndex) => {
    const units = carnet.units;
    const oldNote = units[unitIndex].subjects[subjectIndex].note[goalIndex];
    units[unitIndex].subjects[subjectIndex].note[goalIndex] =
      note === oldNote ? -1 : note;
    setCarnet({ ...carnet, units });
  };
  console.log(carnet);
  return (
    <div>
      {carnet?.units.map((unit, index) => (
        <React.Fragment key={unit.unitId}>
          <CarnetTable
            Ar={!(unit.unitName === "مجال الفرنسية")}
            unit={unit}
            index={index}
            changeRemarque={changeRemarque}
            changeNote={changeNote}
          />
          <br />
        </React.Fragment>
      ))}

      <button
        className="btn btn-block btn-success btn-lg mt-5"
        onClick={() => valider()}
      >
        Confirm Carnet
      </button>
    </div>
  );
};

export default CarnetP;
