import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import ElearningForm from "../../components/Elearning/ElearningForm";
import { EditorState, convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";

import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
import Loader from "../../components/Loading/Loader";

const AddElearning = () => {
  const { register, handleSubmit, errors, reset, control, setValue } =
    useForm();
  const [teacher, setTeacher] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);

    const formData = new FormData(); // Currently empty
    formData.append("title", data.title);
    formData.append("type", data.type);
    formData.append(
      "content",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("subject", data.subjectID);
    if (data.sender !== "All")
      for (var i = 0; i < data.value.length; i++) {
        formData.append("value[]", data.value[i].value);
      }
    formData.append("sender", data.sender);
    for (const elem of files) {
      console.log("This is files", elem.file);
      formData.append("files", elem.file);
    }
    formData.append("day", data.day);

    fetch(API.elearning.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("this is response", response);
        if (response.error) {
          setLoading(false);

          return swal("Error", response.message, "error");
        }
        setLoading(false);

        return swal("Good job!", "ajoute", "success");
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        return swal(
          "Error when we try to send a request",
          e.message + "\n Please Check your internet connection",
          "error"
        );
      });
  };

  useEffect(() => {
    fetch(API.me, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setTeacher(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);

  return (
    <Container
      name="Ajouter un nouveau cour/ exercice/correction d'exercice"
      back={true}
      backRoute={ROUTER.ELEARNING.LIST}
      submit={true}
    >
      {loading && <Loader />}
      {!loading && (
        <ElearningForm
          teacherID={teacher._id}
          register={register()}
          registerRequired={register({ required: true })}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          reset={reset}
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          files={files}
          setFiles={setFiles}
          control={control}
          setValue={setValue}
        />
      )}
    </Container>
  );
};

export default AddElearning;
