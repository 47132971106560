import React from "react";

const Thead = ({ title, colspan, per, Ar }) => {
  return (
    <th
      style={{
        border: "2px solid #1a8cff",
        textAlign: "center",
        color: "#ffaa00",
        boxSizing: "border-box",
        fontWeight: 900,
        fontSize: 16,
        letterSpacing: 0,
        margin: "auto",
        padding: colspan && 0,
        width: per + "%",
      }}
      colSpan={colspan}
    >
      <div>{title}</div>
      {colspan && (
        <div
          style={{
            display: "flex",
            padding: 0,
            fontSize: Ar ? 14 : 10,
            borderTop: "2px solid #1a8cff",
            letterSpacing: 0,
          }}
        >
          <div
            style={{
              flex: 2.9,
              borderRight: "2px solid #1a8cff",
              padding: 2,
            }}
          >
            {Ar ? `حسن جدا` : `Aquis`}
            <br />
            +++
          </div>
          <div
            style={{
              borderRight: "2px solid #1a8cff",
              flex: 3.1,
            }}
          >
            {Ar ? `حسن` : `EA`}
            <br />
            ++
          </div>
          <div style={{ flex: 3.2 }}>
            {Ar ? `متوسط ` : ` NA  `}
            <br />
            +-
          </div>
        </div>
      )}
    </th>
  );
};

export default Thead;
