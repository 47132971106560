import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import ElearningForm from "../../components/Elearning/ElearningForm";
import Container from "../../components/Container/Container";
import { API, ROUTER, STATIC } from "../../constants/env";
import { useParams } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const EditElearning = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, reset, control, setValue } =
    useForm();
  const [elearning, setElearning] = useState(null);
  const [teacher, setTeacher] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const teacherID = JSON.parse(localStorage.getItem("user"))._id;
  useEffect(() => {
    fetch(API.me, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        setTeacher(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
    fetch(API.elearning.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const contentBlock = htmlToDraft(response.content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }

        const files = response.files.map((elem) => STATIC.ROUTE(elem));
        setFiles(files);
        console.log("this is repsonse", response);
        setElearning(response);

        //sethomework(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    console.log("This is data", data);

    const formData = new FormData(); // Currently empty
    formData.append("title", data.title);
    formData.append("type", data.type);
    formData.append(
      "content",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("subject", data.subjectID);
    if (data.sender !== "All")
      for (var i = 0; i < data.value.length; i++) {
        formData.append("value[]", data.value[i].value);
      }
    formData.append("sender", data.sender);
    for (const elem of files) {
      console.log("This is files", elem.file);
      formData.append("files", elem.file);
    }
    formData.append("day", data.day);
    fetch(API.elearning.update(id), {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good", "updated successfully", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Modifier un nouveau cour/ exercice/correction d'exercice"
      back={true}
      backRoute={ROUTER.ELEARNING.LIST}
      submit={true}
    >
      <ElearningForm
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        data={elearning}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        teacherID={teacherID}
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        files={files}
        setFiles={setFiles}
        control={control}
        setValue={setValue}
      />
    </Container>
  );
};

export default EditElearning;
