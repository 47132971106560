const localApi = "http://localhost:5000/";
const serverApi = "https://api.ecole-odyssee.tn/";
const serverClient = "https://sms.ecole-odyssee.tn/assets/images/";
const baseURL = process.env.NODE_ENV == "development" ? localApi : serverApi;
const staticURL =
  process.env.NODE_ENV == "development" ? localApi : serverClient;

exports.ROUTER = {
  RESET: "/reset",
  LOGIN: "/login",
  HOME: "/",

  USERS: {
    LIST: "/user",
    CREATE: "/user/new",
    EDIT: (id) => `/user/edit/${id}`,
  },

  HOMEWORKS: {
    LIST: "/homeworks",
    CREATE: "/homeworks/new",
    EDIT: (id) => `/homeworks/edit/${id}`,
  },
  ELEARNING: {
    LIST: "/elearning",
    CREATE: "/elearning/new",
    EDIT: (id) => `/elearning/edit/${id}`,
  },
  CARNETPREPA: {
    LIST: "/carnetPrepa",
    EDIT: (id) => `/carnetPrepa/st/${id}`,
  },
  CARNETS: {
    LIST: "/carnets",
    EDIT: (id) => `/carnets/st/${id}`,
  },
  ABSENCE: {
    LIST: "/absences",
    CREATE: "/absences/new",
    EDIT: (id) => `/absences/edit/${id}`,
  },

  ERROR: "/404",
};
exports.STATIC = {
  LOGO: "/assets/images/logo.png",
  LOGO_WHITE: `${staticURL}logoSideBar.png`,
  ICON: "/assets/images/icon.png",
  TITLE: "Brasserie 529",
  ROUTE: (file) =>
    `${staticURL}${file.startsWith("/") ? file.substring(1) : file}`,
};
exports.API = {
  auth: {
    login: `${baseURL}teacher-api/v1/auth/login`,
  },
  me: `${baseURL}teacher-api/v1/me/`,

  user: {
    create: `${baseURL}admin-api/v1/homeworks/new`,
    all: `${baseURL}admin-api/v1/homeworks/all`,
    list: `${baseURL}admin-api/v1/homeworks/list`,
    remove: `${baseURL}admin-api/v1/homeworks/delete`,
    info: (id) => `${baseURL}admin-api/v1/homeworks/info/${id}`,
    update: (id) => `${baseURL}admin-api/v1/homeworks/edit/${id}`,
  },

  homeworks: {
    create: `${baseURL}teacher-api/v1/homework/new`,
    all: (idTeacher) => `${baseURL}teacher-api/v1/homework/all/${idTeacher}`,
    list: (idTeacher) => `${baseURL}teacher-api/v1/homework/list/${idTeacher}`,
    remove: `${baseURL}teacher-api/v1/homework/delete`,
    deleteMultiple: `${baseURL}teacher-api/v1/homework/deleteMultiple`,
    info: (teacherID, id) =>
      `${baseURL}teacher-api/v1/homework/info/${teacherID}/${id}`,
    update: (id) => `${baseURL}teacher-api/v1/homework/edit/${id}`,
  },
  elearning: {
    create: `${baseURL}teacher-api/v1/elearning/new`,
    all: `${baseURL}teacher-api/v1/elearning/all`,
    list: `${baseURL}teacher-api/v1/elearning/list`,
    remove: `${baseURL}teacher-api/v1/elearning/delete`,
    deleteMultiple: `${baseURL}teacher-api/v1/elearning/deleteMultiple`,
    info: (id) => `${baseURL}teacher-api/v1/elearning/info/${id}`,
    update: (id) => `${baseURL}teacher-api/v1/elearning/edit/${id}`,
  },
  absences: {
    //API
  },

  carnetStudent: {
    listStudents: `${baseURL}teacher-api/v1/classes/notPrepa`,
    list: `${baseURL}teacher-api/v1/reportCard/list`,
    info: (id) => `${baseURL}teacher-api/v1/reportCard/info/${id}`,
    update: (id) => `${baseURL}teacher-api/v1/reportCard/edit/${id}`,
  },

  carnetPrepa: {
    listPrepas: `${baseURL}teacher-api/v1/classes/prepa`,
    list: `${baseURL}teacher-api/v1/prepaReportCard/list`,
    info: (id, trimestre) =>
      `${baseURL}teacher-api/v1/prepaReportCard/info/${id}/${trimestre}`,
    update: (id) => `${baseURL}teacher-api/v1/prepaReportCard/edit/${id}`,
  },
  overAllAverge: {
    get: (id) => `${baseURL}teacher-api/v1/overallAverage/info/${id}`,
    update: (id) => `${baseURL}teacher-api/v1/overallAverage/edit/${id}`,
  },
  classes: {
    all: `${baseURL}teacher-api/v1/classes/all`,
  },
  students: {
    all: `${baseURL}teacher-api/v1/students/all`,
  },

  subjects: {
    getMySubjects: (id) => `${baseURL}teacher-api/v1/subjects/all/${id}`,
  },
};
