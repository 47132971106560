import React from "react";

const Snote = ({
  note,
  changeNote,
  subjectIndex,
  unitInedx,
  changeNoteEdge,
  min,
  max,
}) => {
  return (
    <td
      style={{
        border: "2px solid #0040ff",
        backgroundColor: "#ffffff",
        boxSizing: "border-box",
        padding: 1,
        display: "flex",
      }}
    >
      <input
        className="form-control"
        style={{
          flex: 1,
          height: "100%",
          textAlign: "center",
          borderRadius: 0,
          fontSize: 17,
          fontWeight: 700,
        }}
        onChange={(e) => {
          if (e.target.value > 20) changeNote(unitInedx, subjectIndex, 20);
          else if (e.target.value < 0 || e.target.value.length === 0)
            changeNote(unitInedx, subjectIndex, 0);
          else changeNote(unitInedx, subjectIndex, e.target.value);
        }}
        value={note}
        defaultValue={note}
        step="0.01"
        type="number"
      />
      <input
        className="form-control"
        style={{
          flex: 1,
          height: "100%",
          textAlign: "center",
          borderRadius: 0,
          fontSize: 17,
          fontWeight: 700,
        }}
        placeholder="min"
        step="0.01"
        type="number"
        onChange={(e) => {
          if (e.target.value > 20)
            changeNoteEdge(unitInedx, subjectIndex, 20, "min");
          else if (e.target.value < 0 || e.target.value.length === 0)
            changeNoteEdge(unitInedx, subjectIndex, 0, "min");
          else changeNoteEdge(unitInedx, subjectIndex, e.target.value, "min");
        }}
        value={min !== 0 && min}
      />
      <input
        className="form-control"
        style={{
          height: "100%",
          textAlign: "center",
          borderRadius: 0,
          fontSize: 17,
          fontWeight: 700,
          flex: 1,
        }}
        onChange={(e) => {
          if (e.target.value > 20)
            changeNoteEdge(unitInedx, subjectIndex, 20, "max");
          else if (e.target.value < 0 || e.target.value.length === 0)
            changeNoteEdge(unitInedx, subjectIndex, 0, "max");
          else changeNoteEdge(unitInedx, subjectIndex, e.target.value, "max");
        }}
        step="0.01"
        type="number"
        placeholder="max"
        value={max !== 0 && max}
      />
    </td>
  );
};

export default Snote;
