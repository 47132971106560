/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div
        className="footer bg-white py-4 d-flex flex-lg-column"
        id="kt_footer"
      >
        {/*begin::Container*/}
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          {/*begin::Copyright*/}
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">2020©</span>
            <Link
              href="#"
              target="_blank"
              className="text-dark-75 text-hover-primary"
            >
              Ntech
            </Link>
          </div>
          {/*end::Copyright*/}
          {/*begin::Nav*/}
          <div className="nav nav-dark">
            {/* <Link
              href="#"
              target="_blank"
              className="nav-link pl-0 pr-5"
            >
              About
            </Link>
            <Link
              href="#"
              target="_blank"
              className="nav-link pl-0 pr-5"
            >
              Team
            </Link>
            <Link
              href="#"
              target="_blank"
              className="nav-link pl-0 pr-0"
            >
              Contact
            </Link> */}
          </div>
          {/*end::Nav*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
}

export default Footer;
