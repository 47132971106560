import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import HomeworkForm from "../../components/Homeworks/HomeworkForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";

const AddHomework = () => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [teacher, setTeacher] = useState({});
  const onSubmit = (data) => {
    console.log(data);
    data.teacherID = teacher._id;
    fetch(API.homeworks.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Homework ajoute", "success");
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetch(API.me, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setTeacher(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);

  return (
    <Container
      name="Ajouter un Homework"
      back={true}
      backRoute={ROUTER.HOMEWORKS.LIST}
      submit={true}
    >
      <HomeworkForm
        teacherID={teacher._id}
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default AddHomework;
