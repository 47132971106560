import React from "react";

const Ssubject = ({ text }) => {
  return (
    <td
      style={{
        fontWeight: "bold",
        fontSize: 14,
        textAlign: "center",
        color: "#ffffff",
        boxSizing: "border-box",
        backgroundColor: "#0040ff",
        border: "1px solid #0040ff",
        borderBottom: "1px solid #ffffff",
      }}
    >
      {text}
    </td>
  );
};

export default Ssubject;
