import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROUTER } from "../../constants/env";

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    window.location = ROUTER.LOGIN;
  }, []);
  return <React.Fragment />;
};

export default Logout;
