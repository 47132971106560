import React from "react";
import Container from "../../../components/Container/Container";
import { ROUTER } from "../../../constants/env";
import CarnetS from "../../../components/Carnet/CarnetS";
import { useParams } from "react-router-dom";

const CarnetStudent = () => {
  const { trimestre } = useParams();
  var tris = localStorage.getItem("tris")
  var trisTitle
  var number
  switch (tris) {
    case "premier": number = 1; break;
    case "deuxieme": number = 2; break;
    case "troisieme": number = 3; break;
  }
  trisTitle = tris ? "| Trimestre :" + number : ""
  return (
    <Container
      name={`Carnet de Note : ${trimestre} ${trisTitle}`}
      back={true}
      backRoute={ROUTER.CARNETS.LIST}
      submit={true}
      pdf
    >
      <CarnetS />
    </Container>
  );
};

export default CarnetStudent;
